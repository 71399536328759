import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    // height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  homepageSectionHeader: {
    fontSize: "36px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
  homepageSectionHeaderContainer: {
    textAlign: "center",
  },
  img: {
    "&:hover": {
      transform: " scale(1.2)",
      transition: "transform .2s",
    },
  },
  commonButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "100%",
    // margin: theme.spacing(1),
    fontSize: "18px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
}))

const HomepageWhyChooseUs = () => {
  const data = useStaticQuery(getImages)
  const patch = data.patch.childImageSharp.fluid
  const pin = data.pin.childImageSharp.fluid
  const medal = data.medal.childImageSharp.fluid
  const classes = useStyles()
  return (
    <div>
      <Box mt={10} mb={6} className={classes.homepageSectionHeaderContainer}>
        <Typography
          component="h2"
          className={classes.homepageSectionHeader}
          color="inherit"
          gutterBottom
        >
          Featured Products
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* 1st Column */}
        <Grid item xs={12} sm={6} md={4}>
          {/* <Box className={classes.imageBox}> */}
          <Card square className={classes.card}>
            <CardActionArea>
              <Image
                fluid={patch}
                alt="Custom embroidered patches"
                className={classes.img}
              />
            </CardActionArea>

            {/* </Box> */}
          </Card>
          <ButtonBase className={classes.commonButton}>Patches</ButtonBase>
        </Grid>
        {/* 2nd column middle */}
        <Grid item xs={12} sm={6} md={4}>
          {/* <Box className={classes.imageBox}> */}
          <Card square className={classes.card}>
            <CardActionArea>
              <Image
                fluid={pin}
                alt="Custom Designed Enamel Pins"
                className={classes.img}
              />
            </CardActionArea>

            {/* </Box> */}
          </Card>
          <ButtonBase className={classes.commonButton}>Pins</ButtonBase>
        </Grid>
        {/* 3rd column */}
        <Grid item xs={12} sm={6} md={4}>
          {/* <Box className={classes.imageBox}> */}
          <Card square className={classes.card}>
            <CardActionArea>
              <Image
                fluid={medal}
                alt="Custom Designed High-End Medals."
                className={classes.img}
              />
            </CardActionArea>

            {/* </Box> */}
          </Card>
          <ButtonBase className={classes.commonButton}>Medals</ButtonBase>
        </Grid>
      </Grid>
    </div>
  )
}
const getImages = graphql`
  {
    patch: file(relativePath: { eq: "vintage_motorcycle_club_patch.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pin: file(relativePath: { eq: "usa_flag_enamel_pin.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medal: file(relativePath: { eq: "custom_metal_medal.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default HomepageWhyChooseUs
