import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FormatQuoteIcon from "@material-ui/icons/FormatQuote"
import PersonIcon from "@material-ui/icons/Person"
const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  homepageSectionHeader: {
    fontSize: "36px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    // textTransform: "uppercase",
  },
  homepageSectionHeaderContainer: {
    textAlign: "center",
  },
  para1: {
    fontSize: "18px",
    color: theme.palette.primary.light,
    letterSpacing: "-0.8 px",
    lineHeight: "1.2",
    marginBottom: theme.spacing(2),
  },
  para2: {
    fontSize: "20px",
    color: theme.palette.primary.light,
    letterSpacing: "-0.8 px",
    lineHeight: "1.4",
    marginBottom: theme.spacing(2),
  },
  commonButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "100%",
    // margin: theme.spacing(1),
    fontSize: "16px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
  testimonialIcon: {
    padding: "0px",
    marginRight: "10px",
    bottom: 0,
  },
  divi: {
    marginTop: "25px",
    marginBottom: "25px",
  },
}))

const HomepageIntro = () => {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  const displayItems = data.display.childImageSharp.fluid
  return (
    <div>
      <Box mb={3} className={classes.homepageSectionHeaderContainer}>
        <Typography
          component="h2"
          className={classes.homepageSectionHeader}
          color="inherit"
          gutterBottom
        >
          Cool Stuff. Great Prices. Awesome Quality.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {/* 1st Column */}
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.card}>
            {/* <FormatQuoteIcon /> */}
            <Typography
              component="body1"
              className={classes.para1}
              display="block"
              gutterBottom
            >
              <strong>ICON Emblem</strong> is a top designer and supplier of
              high quality custom emblem items.
            </Typography>
            <Typography
              component="body1"
              className={classes.para1}
              display="block"
              gutterBottom
            >
              Embroidery, metal, Silicone and PVC emblem products make amazing
              gifts and retail items.
            </Typography>
            <Typography
              component="body1"
              className={classes.para1}
              display="block"
              gutterBottom
            >
              Our patches, badges and pins emblazon professional uniforms across
              the USA and Canada.
            </Typography>
            <Typography
              component="body1"
              className={classes.para1}
              display="block"
              gutterBottom
            >
              And, organizations of all shapes and sizes sell and give away our
              products for promotional brand advertising.
            </Typography>
            <Typography
              component="body1"
              className={classes.para1}
              display="block"
            >
              Check out our custom product line and request a quote for your
              next emblem order today!
            </Typography>
            {/* </CardContent> */}
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={6}>
                <ButtonBase className={classes.commonButton}>
                  Our Products
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ButtonBase className={classes.commonButton}>
                  Get a Quote
                </ButtonBase>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* 2nd column middle */}
        <Grid item xs={12} sm={6} md={4}>
          <Image fluid={displayItems} alt="ICON Emblem custom products." />
        </Grid>
        {/* 3rd column */}
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.card}>
            {/* <CardContent className={classes.cardContent}> */}

            <Typography className={classes.para2} display="block">
              "Just letting you know I received the patches yesterday. They look
              great - thank you! I may do another order this week with a
              different design."
            </Typography>
            {/* <Box> */}
            <CardHeader
              avatar={
                <Avatar className={classes.avatar} variant="square">
                  <FormatQuoteIcon />
                </Avatar>
              }
              title="Ricky R., Michigan"
              subheader="Musician"
            />
            <Divider className={classes.divi} />
            <Typography className={classes.para2} display="block">
              "Got em. In time. I leave Saturday. They look great. thanx!"
            </Typography>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar} variant="square">
                  <FormatQuoteIcon />
                </Avatar>
              }
              title="Richard K., Illinois"
              subheader="Promotional Products Distributor"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
const getImages = graphql`
  {
    display: file(relativePath: { eq: "displayItems.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default HomepageIntro
