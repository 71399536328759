import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/Layout/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// import MainImage from "../components/Layout/HomePage/MainImage"
import HomepageTop from "../components/Layout/HomePage/IconEmblem/HomepageTop"
import HomepageIntro from "../components/Layout/HomePage/IconEmblem/HomepageIntro"
import HomepageWhyChooseUs from "../components/Layout/HomePage/IconEmblem/HomepageWhyChooseUs"
import HomepageFeaturedProducts from "../components/Layout/HomePage/IconEmblem/HomepageFeaturedProducts"
import HomepagePride from "../components/Layout/HomePage/IconEmblem/HomepagePride"
import Container from "@material-ui/core/Container"
import ScrollMenu from "../components/Layout/ScrollMenu"
// import Grid from "@material-ui/core/Grid"
// import UserContext from "../context/UserContext"
import ProductGrid from "../components/Layout/HomePage/ProductGrid"
// import Intro from "../components/Layout/HomePage/Intro"
// import WhyChooseUs from "../components/Layout/HomePage/WhyChooseUs"
// import Pride from "../components/Layout/HomePage/Pride"

import Inquiry from "../components/Inquiry"
import { mergeClasses } from "@material-ui/styles"
const useStyles = makeStyles(theme => ({
  spacer: {
    paddingTop: theme.spacing(6),
  },
}))
const IndexPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <HomepageTop />

        {/* <MainImage /> */}
        <HomepageIntro />
        <HomepageFeaturedProducts />
        {/* <ScrollMenu /> */}
        {/* <Intro /> */}
        {/* <ProductGrid /> */}
        <HomepageWhyChooseUs />
        <HomepagePride />
        {/* <MainImage /> */}
        <div className={classes.spacer} />
        <Inquiry />
      </Container>
    </Layout>
  )
}

export default IndexPage
