import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import ForumIcon from "@material-ui/icons/Forum"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import {
  Heart as HeartIcon,
  ShoppingCart as ShoppingCartIcon,
} from "react-feather"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    margin: "30px auto",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    border: "2px solid #F0F0F0",
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      transition: "0.3s",
    },
  },
  // icon: {
  //   fontSize: DonutLarge
  // },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardSubhead: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontSize: "24px",
    letterSpacing: "3px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  homepageSectionHeaderContainer: {
    paddingTop: theme.spacing(6),
    textAlign: "center",
  },
  homepageSectionHeader: {
    fontSize: "36px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    // textTransform: "uppercase",
  },
  description: {},
  actionButton: {
    align: "center",
  },
}))

const HomepageWhyChooseUs = () => {
  const classes = useStyles()
  return (
    <div>
      <Box mb={3} className={classes.homepageSectionHeaderContainer}>
        <Typography
          component="h2"
          className={classes.homepageSectionHeader}
          color="inherit"
          gutterBottom
        >
          Why Choose ICON Emblem?
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* 1st Column */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card} square>
            <ButtonBase>
              <Avatar className={classes.avatar}>
                <SvgIcon fontSize="large">
                  <HeartIcon />
                </SvgIcon>
              </Avatar>
            </ButtonBase>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardSubhead}>
                Top Quality
              </Typography>
              <Typography>
                ICON Emblem is a top emblem source for customers who require
                high quality products at great prices. Check our quality
                yourself before you buy. Request a free random sample and we'll
                send one through the mail.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className={classes.actionButton}
                color="primary"
                variant="outlined"
                fullWidth
              >
                Request a Sample
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {/* 2nd column middle */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card} square>
            <Avatar className={classes.avatar}>
              <ForumIcon />
            </Avatar>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardSubhead}>
                Personalized Service
              </Typography>
              <Typography className={classes.description}>
                Our dedicated represantatives are here to help you get the best
                possible custom products. Your rep will assist you wi all the
                details and guide you through the ordering process. Getting in
                touch is as easy as sending a text message or email.
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" variant="outlined" fullWidth>
                Get In Touch
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {/* 3rd column */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card} square>
            <Avatar className={classes.avatar}>
              <ThumbUpIcon />
            </Avatar>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardSubhead}>
                Good Prices
              </Typography>
              <Typography>
                You don't need to sacrifice quality to get a great emblem item
                at an amazing price. Fill out a quote request form or check out
                our ballpark pricing guides to see for yourself.
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" variant="outlined" fullWidth>
                Request a Quote
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default HomepageWhyChooseUs
