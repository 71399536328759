import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import ButtonBase from "@material-ui/core/ButtonBase"

const images = [
  {
    id: 1,
    url: "/static/images/grid-list/breakfast.jpg",
    title: "Breakfast",
    width: "35%",
  },
  {
    id: 2,
    url: "/static/images/grid-list/burgers.jpg",
    title: "Burgers",
    width: "30%",
  },
  {
    id: 3,
    url: "/static/images/grid-list/camera.jpg",
    title: "Camera",
    width: "30%",
  },
  {
    id: 4,
    url: "/static/images/grid-list/breakfast.jpg",
    title: "Breakfast",
    width: "30%",
  },
  {
    id: 5,
    url: "/static/images/grid-list/burgers.jpg",
    title: "Burgers",
    width: "30%",
  },
  {
    id: 6,
    url: "/static/images/grid-list/camera.jpg",
    title: "Camera",
    width: "35%",
  },
]

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },

  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    margin: theme.spacing(0.75),
    position: "relative",
    height: 330,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 200,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}))

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9]

const ProductGrid = () => {
  const classes = useStyles()
  return (
    <div>
      <CssBaseline />

      <h1>Featured Products</h1>
      <Grid container spacing={4}>
        {images.map(image => (
          <ButtonBase
            focusRipple
            key={image.id}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: image.width,
            }}
          >
            <span
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
                <span className={classes.imageMarked} />
              </Typography>
            </span>
          </ButtonBase>
        ))}
      </Grid>
    </div>
  )
}

export default ProductGrid
