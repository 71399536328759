import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core"
import HomepageSlider from "./HomepageSlider"
const useStyles = makeStyles(theme => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 0),
      paddingRight: 0,
    },
  },
  sliderDiv: {
    position: "relative",
    padding: theme.spacing(0, 0, 0, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 0),
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  homeBlackBox: {
    backgroundColor: theme.palette.common.black,
    margin: 0,
    padding: theme.spacing(3),
    textAlign: "center",
  },
  tableText: {
    color: theme.palette.common.white,
    fontSize: "16px",
  },
  homeTable: {
    border: "1px solid white",
  },
  homeTableRow: {
    "&:hover": {
      // textDecoration: "none",
      backgroundColor: theme.palette.common.almostBlack,
    },
    hover: {
      // textDecoration: "none",
      backgroundColor: theme.palette.common.almostBlack,
    },
  },
  homeTableCell: {
    padding: theme.spacing(1),
    "&:hover": {
      // textDecoration: "none",
      backgroundColor: theme.palette.common.almostBlack,
    },
    hover: {
      // textDecoration: "none",
      backgroundColor: theme.palette.common.almostBlack,
    },
  },
  tableButton: {
    color: theme.palette.common.white,
    fontSize: "18px",
    letterSpacing: "2px",
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",

    width: "100%",
    height: theme.spacing(5),
    "&:hover": {
      // textDecoration: "none",
      backgroundColor: theme.palette.common.almostBlack,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  specialTableButton: {
    color: "#89cff0",
    fontSize: "18px",
    letterSpacing: "2px",
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    width: "100%",
    height: theme.spacing(5),
  },
  contrastButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    textTransform: "uppercase",
    fontSize: "18px",
    letterSpacing: "2px",
    // fontWeight: "bold",
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  boxTitle: {
    color: theme.palette.common.white,
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}))

export default function HomepageTop(props) {
  const classes = useStyles()
  const { post } = props

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <div className={classes.mainFeaturedPostContent}>
            <Card square className={classes.homeBlackBox}>
              <Box mb={2}>
                <Typography
                  component="h1"
                  className={classes.boxTitle}
                  color="inherit"
                  gutterBottom
                >
                  All kinds <br /> of emblems
                </Typography>
              </Box>
              <Table className={classes.homeTable}>
                <TableRow hover className={classes.homeTableRow}>
                  <TableCell className={classes.homeTableCell}>
                    <Button
                      size="large"
                      fullWidth
                      className={classes.tableButton}
                      variant="text"
                      color="inherit"
                      component={Link}
                      to="/embroidered-patches"
                    >
                      Patches
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell className={classes.homeTableCell}>
                    <Button
                      size="large"
                      fullWidth
                      className={classes.tableButton}
                      variant="text"
                      color="inherit"
                      component={Link}
                      to="/lapel-pins"
                    >
                      Pins
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell className={classes.homeTableCell}>
                    <Button
                      size="large"
                      fullWidth
                      className={classes.tableButton}
                      style={{ lineHeight: 0.9 }}
                      variant="text"
                      color="inherit"
                      component={Link}
                      to="/medals-coins"
                    >
                      Coins & Medals
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell className={classes.homeTableCell}>
                    <Button
                      size="large"
                      fullWidth
                      className={classes.tableButton}
                      style={{ lineHeight: 0.9 }}
                      variant="text"
                      color="inherit"
                      component={Link}
                      to="/keychains"
                    >
                      Key Chains
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell className={classes.homeTableCell}>
                    <Button
                      size="large"
                      fullWidth
                      className={classes.tableButton}
                      variant="text"
                      color="inherit"
                      component={Link}
                      to="/dress-items-cufflinks-tie-bars"
                    >
                      Cufflinks
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell className={classes.homeTableCell}>
                    <Button
                      size="large"
                      fullWidth
                      className={classes.specialTableButton}
                      style={{ lineHeight: 0.9 }}
                      variant="text"
                      component={Link}
                      to="/cta"
                    >
                      Chicago CTA
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
              <ButtonBase
                className={classes.contrastButton}
                component={Link}
                to="/products"
              >
                More
              </ButtonBase>
            </Card>
          </div>
        </Grid>
        <Grid item xs={false} md={9}>
          <div className={classes.sliderDiv}>
            <HomepageSlider />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

HomepageTop.propTypes = {
  post: PropTypes.object,
}
