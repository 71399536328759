import React, { useState, useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  SvgIcon,
} from "@material-ui/core"

import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
// import useOnScreen from "../../../utils/hooks/useOnScreen"
import Counter from "../../../DropIn/Counter"
import { Truck as TruckIcon, Sun as SunIcon } from "react-feather"

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  help: {
    fontSize: "42px",
    letterSpacing: "1.5px",
    fontWeight: "light",
    textTransform: "uppercase",
    lineHeight: "97%",
  },
  head: {
    textAlign: "center",
    marginTop: theme.spacing(6),
  },
  header: {
    fontSize: "38px",
    letterSpacing: "2px",
    fontWeight: "light",
    lineHeight: "97%",
  },
  counter: {
    textAlign: "center",
    marginTop: theme.spacing(8),
  },
  countNumber: {
    fontSize: "52px",
    letterSpacing: "1.5px",
    lineHeight: "97%",
  },
  icon: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginBottom: theme.spacing(2),
    color: "#8c8c8c",
  },
  caption: {
    fontSize: "14px",
    color: "#8c8c8c",
  },
  img: {},
  spacer: {
    marginTop: theme.spacing(3),
  },
}))

const HomepagePride = () => {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  const [renderCount, setRenderCount] = useState(0)
  // const [jobCount, setJobCount] = useState(100)
  const ref = useRef()
  // const onScreen = useOnScreen(ref, "-300px")
  const pridePatch = data.pridePatch.childImageSharp.fluid

  // useEffect(() => {
  //   renderCount < 7 && setRenderCount(renderCount + 1)
  // }, [onScreen])

  return (
    <div>
      <div className={classes.head}>
        <Typography className={classes.header} gutterBottom>
          Worn With Pride Since 2007
        </Typography>
      </div>

      <Grid container spacing={4}>
        {/* 1st Column */}
        <Grid item xs={12} sm={6} md={3}>
          <Box pt={10}>
            <Typography className={classes.help} gutterBottom>
              Here to help you get what you want
            </Typography>
            <Typography variant="body2" gutterBottom>
              Don't settle for anything less than the best. Get in touch with
              ICON Emblem today and you'll have a set of great looking emblem
              products in no time at all.
            </Typography>
            <div className={classes.spacer} />
            <Button size="small" color="primary" variant="outlined">
              Our Products
            </Button>
          </Box>
        </Grid>
        {/* 2nd column middle */}
        <Grid item xs={12} sm={6} md={6}>
          <Image
            fluid={pridePatch}
            alt="ICON Emblem: Made With Pride"
            className={classes.img}
          />
        </Grid>
        {/* 3rd column */}
        <Grid item xs={12} sm={6} md={3}>
          <Box ref={ref}>
            <Box className={classes.counter}>
              <SvgIcon className={classes.icon}>
                <SunIcon />
              </SvgIcon>
              <Typography className={classes.countNumber}>
                {/* {renderCount < 6 && onScreen ? <Counter end={11} /> : "11"} */}
                11
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                className={classes.caption}
              >
                Years in business and counting
              </Typography>
            </Box>
            <Box className={classes.counter}>
              <SvgIcon className={classes.icon}>
                <TruckIcon />
              </SvgIcon>
              <Typography className={classes.countNumber}>
                {/* {renderCount < 6 && onScreen ? <Counter end={4999} /> : "4999"} */}
                4999
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                className={classes.caption}
              >
                More than 5000 completed jobs
              </Typography>
            </Box>{" "}
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
const getImages = graphql`
  {
    pridePatch: file(relativePath: { eq: "wornwithpride.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default HomepagePride
